.dataimg-arrow-down {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-down.svg"); background-repeat: no-repeat;}

.dataimg-arrow-left {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-left.svg"); background-repeat: no-repeat;}

.dataimg-arrow-light-left {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-light-left.svg"); background-repeat: no-repeat;}

.dataimg-arrow-light-right {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-light-right.svg"); background-repeat: no-repeat;}

.dataimg-arrow-right-red {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-right-red.svg"); background-repeat: no-repeat;}

.dataimg-arrow-right {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-right.svg"); background-repeat: no-repeat;}

.dataimg-arrow-up {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-up.svg"); background-repeat: no-repeat;}

.dataimg-avatar {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/avatar.svg"); background-repeat: no-repeat;}

.dataimg-blesk-krimi {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/blesk-krimi.svg"); background-repeat: no-repeat;}

.dataimg-camera {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/camera.svg"); background-repeat: no-repeat;}

.dataimg-check-icon {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/check-icon.svg"); background-repeat: no-repeat;}

.dataimg-cross {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/cross.svg"); background-repeat: no-repeat;}

.dataimg-email {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/email.svg"); background-repeat: no-repeat;}

.dataimg-facebook {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/facebook.svg"); background-repeat: no-repeat;}

.dataimg-facebook2 {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/facebook2.svg"); background-repeat: no-repeat;}

.dataimg-login-icon {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/login-icon.svg"); background-repeat: no-repeat;}

.dataimg-login {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/login.svg"); background-repeat: no-repeat;}

.dataimg-logo-grey {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/logo-grey.svg"); background-repeat: no-repeat;}

.dataimg-logo {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/logo.svg"); background-repeat: no-repeat;}

.dataimg-payment-gopay {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/payment-gopay.svg"); background-repeat: no-repeat;}

.dataimg-payment-mastercard {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/payment-mastercard.svg"); background-repeat: no-repeat;}

.dataimg-payment-sms {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/payment-sms.svg"); background-repeat: no-repeat;}

.dataimg-payment-visa {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/payment-visa.svg"); background-repeat: no-repeat;}

.dataimg-play-white {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/play-white.svg"); background-repeat: no-repeat;}

.dataimg-play {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/play.svg"); background-repeat: no-repeat;}

.dataimg-premium-2 {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/premium-2.svg"); background-repeat: no-repeat;}

.dataimg-search {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/search.svg"); background-repeat: no-repeat;}

.dataimg-social-line-facebook {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/social-line-facebook.svg"); background-repeat: no-repeat;}

.dataimg-social-line-mail {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/social-line-mail.svg"); background-repeat: no-repeat;}

.dataimg-social-line-messenger {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/social-line-messenger.svg"); background-repeat: no-repeat;}

.dataimg-social-line-twitter {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/social-line-twitter.svg"); background-repeat: no-repeat;}

.dataimg-social-line-whatsapp {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/social-line-whatsapp.svg"); background-repeat: no-repeat;}

.dataimg-tag-blesk-premium {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/tag-blesk-premium.svg"); background-repeat: no-repeat;}

.dataimg-twitter {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/twitter.svg"); background-repeat: no-repeat;}

.dataimg-twitter2 {background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/twitter2.svg"); background-repeat: no-repeat;}

.dataimg-calculator {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/calculator.svg"); background-repeat: no-repeat;}

.dataimg-eu_flag {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/eu_flag.svg"); background-repeat: no-repeat;}

.dataimg-gbp_flag {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/gbp_flag.svg"); background-repeat: no-repeat;}

.dataimg-triangle-down {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/triangle-down.svg"); background-repeat: no-repeat;}

.dataimg-triangle-right {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/triangle-right.svg"); background-repeat: no-repeat;}

.dataimg-triangle-up {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/triangle-up.svg"); background-repeat: no-repeat;}

.dataimg-usa_flag {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/usa_flag.svg"); background-repeat: no-repeat;}
